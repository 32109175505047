import React from 'react'
import GatsbyImage from 'gatsby-image'
import { Link } from 'gatsby'
import { format } from 'react-string-format'

class CardService extends React.Component {
    render() {
        const serviceItemData = this.props.data
        const serviceItemElements = serviceItemData.elements
        const title = serviceItemElements.title.value
        const parentSlug = this.props.slug
        const slug = `/` + parentSlug + `/${serviceItemElements.slug.value}`
        const teaserImage = serviceItemElements.teaser_image

        const showServiceItems = this.props.showServiceItems
        var serviceItems = ""
        {showServiceItems != "false" ? (
            serviceItems = serviceItemElements.service_item.value
        ) : ""}
        return (
            <div className="col-lg-4">
                <div className="card card--service">
                    <Link to={slug} data-tracking-label={format('Service_{0}', title)}>
                        <div className="card-thumb">
                            {teaserImage.value[0] ? (
                                <GatsbyImage fluid={teaserImage.value[0].fluid} />
                            ) : ""}
                        </div>
                        <div className="card-content">
                            <h3>{title}</h3>
                            {showServiceItems != "false" ? (
                                <ul className="card-content__list">
                                    {serviceItems &&
                                        serviceItems.map(serviceItem => (
                                            <li className="card-content__list-item" key={serviceItem.system.id}>
                                                {serviceItem.elements.title.value} ({serviceItem.elements.price.value})
                                            </li>
                                        ))}
                                </ul>
                            ) : ""}
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}

export default CardService

