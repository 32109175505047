import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import CardService from '../components/CardService'

class ServicesOverviewRoute extends React.Component {
    render() {
        const servicesOverviewData = this.props.data
        const title = servicesOverviewData.kontentItemSiteMetadata.elements.title.value
        const kontentItemServicesOverviewPage = servicesOverviewData.kontentItemServicesOverviewPage
        const servicesOverviewElements = kontentItemServicesOverviewPage.elements
        var subtitle = servicesOverviewElements.title.value
        var description = servicesOverviewElements.meta_description.value

        const serviceItems = []
        const servicesUrl = servicesOverviewElements.slug.value
        const services = servicesOverviewElements.services.value
        services.forEach(service => {
            serviceItems.push(<CardService data={service} slug={servicesUrl} showServiceItems="true" key={service.id} />)
        })

        return (
            <Layout>
                <div>
                    <Meta title={subtitle} postfix={title} description={description} />
                    <Header />
                    <PageHeading data={subtitle} />
                    <section className="recent-cases">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row normal-col-gap">
                                        {serviceItems}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default ServicesOverviewRoute

export const pageQuery = graphql`
  query ServicesOverviewQuery($codename: String!) {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }    
    kontentItemServicesOverviewPage(system: {codename: {eq: $codename}}) {
      id
      system {
        id
      }
      elements {
        slug {
            value
        }
        description {
          value
        }
        meta_description  {
          value
        }
        title {
          value
        }
        services {
          value {
            ... on kontent_item_service {
              id
              elements {
                title {
                  value
                }
                slug {
                  value                      
                }
                teaser_image {
                  value {
                    fluid(maxWidth: 810) {
                      ...KontentAssetFluid
                    }
                    description
                    name
                    url
                  }
                }
                service_item {
                  value {
                    ... on kontent_item_service_item {
                      system {
                        id
                      }
                      elements {
                        title {
                          value
                        }
                        slug {
                          value                      
                        }
                        price {
                          value
                        }
                        teaser_image {
                          value {
                            fluid(maxWidth: 810) {
                            ...KontentAssetFluid
                          }
                          description
                          name
                            url
                          }
                        }
                        price {
                          value
                        }
                        time {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allKontentItemService(filter: {preferred_language: {eq: "en-US"}}) {
      nodes {
        id
        elements {
          slug {
            value
          }
          title {
            value
          }
          teaser_image {
            value {
              fluid(maxWidth: 700) {
                ...KontentAssetFluid
              }
              name
              url
            }
          }
          service_item {
            value {
              ... on kontent_item_service_item {
                system {
                  id
                }
                elements {
                  title {
                    value
                  }
                  slug {
                    value                      
                  }
                  price {
                    value
                  }
                  teaser_image {
                    value {
                      fluid(maxWidth: 810) {
                      ...KontentAssetFluid
                    }
                    description
                    name
                      url
                    }
                  }
                  price {
                    value
                  }
                  time {
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
